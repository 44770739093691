import React from "react";


function CallText() {
  return (
    <div>
      <section fluid className="profile">
        <h1>Right Talent at Right Time at Right Place!</h1>
          <p>
             Discover the perfect match for your company’s success – exceptional talent, precisely timed, in the right place. Unlock growth today!
          </p>
          <a href="http://localhost:3000/contact" className="button"><span>Contact US </span></a>
      </section>
    </div>
  );
}
export default CallText;