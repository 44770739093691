import React from "react";
import Typewriter from "typewriter-effect";

function Type() {
  return (
    <Typewriter
      options={{
        strings: [
          "We Specialize in Providing​ Recruitment Solutions",
          "Top Overseas Recruitment",
          "Global Recruitment",
          "Comprehensive HR Recruitment",
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default Type;
