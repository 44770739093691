import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import service21 from "../../Assets/service21.jpg";
import TeamCard from "./TeamCard";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";
import atik from "../../Assets/Projects/atik.jpeg";
import emotion from "../../Assets/Projects/emotion.png";
import faim from "../../Assets/Projects/faim.jpeg";
import kailas from "../../Assets/Projects/kailas.jpeg";
import faisal from "../../Assets/Projects/faisal.jpeg";

function Team() {
  return (
    <Container fluid className="abo-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Our <strong className="purple">Team</strong>
            </h1>
            <TeamCard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={service21} alt="about" className="img-fluid" />
          </Col>
        </Row>
    
      </Container>

      <Container>
        <h1 className="team-heading">
        Our <strong className="purple">Leadership Team </strong>
        </h1>
        <Row style={{ justifyContent: "Justify", paddingBottom: "10px" }}>
          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Mr Ismail BhanaPatel"
              description="Vice Chairperson"
            />
          </Col>

          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={faisal}
              isBlog={false}
              title="Faizal Bhaiji"
              description="Manager"
 
            />
          </Col>

          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={faim}
              isBlog={false}
              title="Faim Ghanchi"
              description="Regional Head - Europe"     
            />
          </Col>

          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={atik}
              isBlog={false}
              title="Atik Ghanchi"
              description="Chief Financial Officer"
 
            />
          </Col>

          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={kailas}
              isBlog={false}
              title="Kailash Ahir"
              description="Regional Head - Gulf"  
 
            />
          </Col>

          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Saalim Jibhai"
              description="Co-Founder" 
            />
          </Col>





          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Saalim Jibhai"
              description="Co-Founder" 
            />
          </Col>



          
          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Saalim Jibhai"
              description="Co-Founder" 
            />
          </Col>



          
          <Col md={4} className="team-card">
            <ProjectCard
              imgPath={emotion}
              isBlog={false}
              title="Saalim Jibhai"
              description="Co-Founder" 
            />
          </Col>


















        </Row>
      </Container> 

    </Container>
  );
}

export default Team;
