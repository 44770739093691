import React from "react";
import Card from "react-bootstrap/Card";
// import Button from "react-bootstrap/Button";
// import { CgWebsite } from "react-icons/cg";
// import { BsGithub } from "react-icons/bs";

function ProjectCards(props) {
  return (
    <Card className="team-card-view">
      <Card.Img variant="top" src={props.imgPath} alt="card-img" />
      <Card.Body>
        <Card.Title  className="team-title" style={{ textAlign: "start" }}>{props.title}</Card.Title>
        <Card.Text  className="team-text" style={{ textAlign: "justify"}}>{props.description}</Card.Text>
        {"\n"}
        {"\n"}
      </Card.Body>
    </Card>
  );
}
export default ProjectCards;
