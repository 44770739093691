import React from "react";
import Card from "react-bootstrap/Card";

function Growthcard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote">
          <p style={{ textAlign: "justify" }}>
          Our aim is to be a trusted partner in providing the right talent to companies
          and supporting the career growth of candidates. At BhanaPatel Associates, we are
          driven by our Core Values of Business Ethics, Integrity, Transparency, Innovation,
          and Excellence. We strive to uphold these values in every service we offer, to create
          long-lasting relationships with our clients and candidates.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default Growthcard;
