import React from "react";
import Card from "react-bootstrap/Card";

function Rangecard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote">
          <p style={{ textAlign: "justify" }}>
          Our comprehensive range of HR services includes HR Consultancy, Staffing Services,
          Healthcare Recruitment, Outsourcing, and Overseas Recruitment. We cater to companies
          in India, Gulf countries, Europe, and other regions across the world.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default Rangecard;
