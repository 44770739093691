import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import ProjectCard from "./ProjectCards";
import news from "../../Assets/Projects/news.jpg";
import news1 from "../../Assets/Projects/news1.webp";
import news2 from "../../Assets/Projects/news2.jpg";
import news3 from "../../Assets/Projects/news3.jpg";
import news4 from "../../Assets/Projects/news4.jpg";
import news5 from "../../Assets/Projects/news5.jpeg";
import news6 from "../../Assets/Projects/news6.jpg";
import news7 from "../../Assets/Projects/news7.png";
import news8 from "../../Assets/Projects/news8.png";


function News() {
  return (
    <Container fluid className="ab-section">
      <Particle />
      <Container>
        <h1 className="news-heading">
        Our <strong className="purple">News & Events </strong>
        </h1>
        <Row style={{ justifyContent: "justify", paddingBottom: "10px" }}>
          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news}
              isBlog={false}
              title="CNPC awarded $194 million EPC contract for Iraq’s Rumaila oilfield"
              ghLink="https://www.oilandgasmiddleeast.com/news/cnpc-awarded-194-million-epc-contract-for-iraqs-rumaila-oilfield"
            />
          </Col>

          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news1}
              isBlog={false}
              title="Looking for a career change in the Middle East? 5 reasons to consider the oil and gas industry"
              ghLink="https://www.oilandgasmiddleeast.com/news/looking-for-a-career-change-in-the-middle-east-5-reasons-to-consider-the-oil-and-gas-industry"
 
            />
          </Col>

          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news2}
              isBlog={false}
              title="UAE’s Dana Gas receives $80 million payment from Iraq"
              ghLink="https://www.oilandgasmiddleeast.com/news/uaes-dana-gas-receives-80-million-payment-from-iraq"
          
            />
          </Col>

          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news3}
              isBlog={false}
              title="Algeria’s Sonatrach signs $800 million contract for oil and gas projects"
              ghLink="https://www.oilandgasmiddleeast.com/news/algerias-sonatrach-signs-800-million-contract-for-oil-and-gas-projects"
 
            />
          </Col>

          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news4}
              isBlog={false}
              title="Qatar to build major oil refinery in Iraq and launch new company for oil tankers"
              ghLink="https://www.oilandgasmiddleeast.com/news/qatar-to-build-major-oil-refinery-in-iraq-and-launch-new-company-for-oil-tankers"
 
            />
          </Col>

          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news5}
              isBlog={false}
              title="Bahrain issues Golden Licence to firms with $1.4 billion project investments"
              ghLink="https://www.oilandgasmiddleeast.com/news/bahrain-issues-golden-licence-to-firms-with-1-4-billion-project-investments"
            />
          </Col>





          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news6}
              isBlog={false}
              title="Saudi Arabia’s SABIC awards major contract for $76 million solar project"
              ghLink="https://www.oilandgasmiddleeast.com/news/saudi-arabias-sabic-awards-major-contract-for-76-million-solar-project"
            />
          </Col>



          
          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news7}
              isBlog={false}
              title="L&T to bid for $20 billion oil and gas projects, head of energy says"
              ghLink="https://www.oilandgasmiddleeast.com/news/lt-to-bid-for-20-billion-oil-and-gas-projects-head-of-energy-says"
            />
          </Col>



          
          <Col md={4} className="news-card">
            <ProjectCard
              imgPath={news8}
              isBlog={false}
              title="Green hydrogen could create up to 2 million jobs globally per year: Deloitte"
              ghLink="https://www.oilandgasmiddleeast.com/news/green-hydrogen-could-create-up-to-2-million-jobs-globally-per-year-deloitte"
            />
          </Col>















        </Row>
      </Container> 

    </Container>
  );
}

export default News;
