import React from "react";
import Card from "react-bootstrap/Card";

function ServiceCards(props) {
  return (
    <Card className="contact-card-view">
      <Card.Body>
        <Card.Title>{props.title}</Card.Title>
        <Card.Text style={{textAlign: "center" }}>
          {props.description}
        </Card.Text>
        {"\n"}
        {"\n"}
      </Card.Body>
    </Card>
  );
}
export default ServiceCards;
