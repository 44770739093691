import React from "react";
import Card from "react-bootstrap/Card";

function DemoCard() {
  return (
    <Card className="quoteer-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            At Bhanapatel Associates, we specialize in providing recruitment solutions to various industries in India and overseas. Our services cater to companies in the following markets:
            <br />
            Overseas Recruitment to Gulf Countries :  We are recruiting to Europe, US, Africa, Asia Pacific, and Gulf countries, including the UAE, Qatar, Kuwait, Oman, Bahrain, and Saudi Arabia.
            <br />
            Domestic Recruitment within India : We have extensive experience in the Indian job market and a vast network of qualified candidates across various industries and positions. Our team of recruiters has a deep understanding of the Indian job market and can provide customized recruitment solutions to meet your needs.
            </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default DemoCard;
