import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import eart from "../../Assets/eart.png";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import DemoCard from "./DemoCard";
import usa from "../../Assets/Projects/usa.jpg";
import uk from "../../Assets/Projects/uk.jpg";
import dubai from "../../Assets/Projects/dubai.webp";

function MarketNew() {
  return (
    <Container fluid className="aboutt-section">
      <Particle />
      <Container>

        <h1 className="project-heading">
          Market <strong className="purple">Us </strong>
        </h1>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <DemoCard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "30px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={eart} alt="about" className="img-fluid" /> 
          </Col>
        </Row>
      </Container>

      <Container>
        <h1 className="project-heading">
        Global <strong className="purple">Recruitments</strong>
        </h1>
        <p style={{ color: "white" }}>
          We recruit candidates from Asian countries for work in the Europe, and Gulf 
          countries,with respective laws.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={dubai}
              isBlog={false}
              title="Recruitment to Middle East"
              description="To recruit Indian workers for Gulf countries, we follow steps like identifying job requirements, developing a recruitment strategy, advertising the job, screening/selecting candidates, obtaining permits/visas, providing orientation/support, and ensuring compliance with labor laws for a seamless transition for both workers and employers."
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={uk}
              isBlog={false}
              title="Recruitment to Europe"
              description="Recruiting Asian candidates for work in Europe involves defining requirements, developing a strategy, advertising the job, screening/selecting candidates, obtaining permits/visas, and providing cultural orientation/support. Careful planning and cultural sensitivity are key for a successful experience."       
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={usa}
              isBlog={false}
              title="Recruitment to USA & Canada"
              description="Our recruitment process for candidates from Asian countries seeking work in the USA or Canada involves several steps. We prioritize understanding cultural differences, providing relocation support, considering language proficiency, verifying qualifications, and taking into account time zone differences."
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default MarketNew;


