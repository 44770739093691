import React from "react";
import { Col, Row } from "react-bootstrap";
import service2 from "../../Assets/service2.png";
import service5 from "../../Assets/service5.jpg";
import service10 from "../../Assets/service10.jpg";
import service11 from "../../Assets/service11.jpg";
import service7 from "../../Assets/service7.jpg";
import service8 from "../../Assets/service8.jpg";
import service9 from "../../Assets/service9.jpg";



// import { CgCPlusPlus } from "react-icons/cg";
// import {
//   DiJavascript1,
//   DiReact,
//   DiNodejs,
//   DiMongodb,
//   DiPython,
//   DiGit,
//   DiJava,
// } from "react-icons/di";
// import {
//   SiRedis,
//   SiFirebase,
//   SiNextdotjs,
//   SiSolidity,
//   SiPostgresql,
// } from "react-icons/si";
// import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" , paddingTop: "50px"}}>
      <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service2} alt="about" />
           <div className="tecno"> 
           <h6>Personalized and <strong className="purple">Transparent Services:</strong></h6>
           <p>We provide personalized and transparent recruitment solutions to our clients, ensuring that they have full visibility into the recruitment process.</p>
         </div> 
        </div>
        </Col>


      <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service5} alt="about" />
           <div className="tecno"> 
           <h6>Extensive <strong className="purple">Network:</strong></h6>
           <p>We have an extensive network of qualified candidates across various industries and positions, allowing us to connect top talent with leading organizations.</p>
         </div> 
        </div>
        </Col>


        <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service10} alt="about" />
           <div className="tecno"> 
           <h6>Comprehensive <strong className="purple">Range of HR Services:</strong></h6>
           <p>We provide a comprehensive range of HR services, including HR consultancy, staffing services, healthcare recruitment, outsourcing, and overseas recruitment, to meet the diverse needs of our clients.</p>
         </div> 
        </div>
        </Col>



        <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service11} alt="about" />
           <div className="tecno"> 
           <h6>Training and <strong className="purple">Skill Upgradation:</strong></h6>
           <p> We provide training and skill upgradation programs to our candidates, ensuring that they are equipped with the latest skills and knowledge to meet the evolving needs of the job market.</p>
         </div> 
        </div>
        </Col>


        <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service7} alt="about" />
           <div className="tecno"> 
           <h6>Cost-effective <strong className="purple">Solutions:</strong></h6>
           <p>We provide cost-effective recruitment solutions to our clients, helping them reduce recruitment costs while ensuring that they find the right talent.</p>
         </div> 
        </div>
        </Col>


        <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service8} alt="about" />
           <div className="tecno"> 
           <h6>Timely and <strong className="purple">Efficient Services:</strong></h6>
           <p>We provide timely and efficient recruitment solutions to our clients, ensuring that they can fill their vacancies quickly and efficiently.</p>
         </div> 
        </div>
        </Col>


        <Col xs={8} md={5} className="tech-icons">
        <div className="new">
        <img src={service9} alt="about" />
           <div className="tecno"> 
           <h6>Expertise <strong className="purple">and Experience:</strong></h6>
           <p>Our team of recruiters and HR consultants have extensive expertise and experience in the recruitment industry, allowing us to provide high-quality solutions to our clients.</p>
         </div> 
        </div>
        </Col>



{/* 
      <Col xs={4} md={2} className="tech-icons">
        <TbBrandGolang />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiNodejs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiSolidity />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNextdotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiFirebase />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <DiJava />
      </Col> */}
    </Row>
  );
}

export default Techstack;
