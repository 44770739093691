import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ServiceCard from "./ServiceCards";
import Particle from "../Particle";


function Services() {
  return (
    <Container fluid className="cont-section">
      <Particle />
      <Container>
        <h1 className="contact-heading">
        Cont<strong className="purple">acts</strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px"}}>
          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="SURAT OFFICE"
              description="Address: Meet & Meer Compound,Near Canal,Behind Booker Wholesale, 
                           Opp. Sanabil Bakery, Near Booker Wholesale UNN-BHESTAN-SURAT."
            />
          </Col>

          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="E-MAIL"
              description="Contact@bhanapatel.com"
            />
          </Col>

          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="CHAT WITH US"
              description="MO:90331 27400  MO:90339 16551 MO:9099638398"              
            />
          </Col>
        </Row>
       







        <Row style={{ justifyContent: "center", paddingBottom: "10px"}}>
          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="VADODARA OFFICE"
              description="Address: C-385, Emrald One,Jetalpur Road,Near 
                           Gujarat kidney Hospital,Alkapuri, Vadodara."
            />
          </Col>

          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="E-MAIL"
              description="Contact@bhanapatel.com"
            />
          </Col>

          <Col md={4} className="contact-card">
            <ServiceCard
              isBlog={false}
              title="CHAT WITH US"
              description="MO:93168 09108 MO:63549 38733  MO:9879778086"              
            />
          </Col>
        </Row>


      </Container>
    </Container>
  );
}

export default Services;
