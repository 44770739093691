import React from "react";
import Card from "react-bootstrap/Card";

function MissionCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote">
          <p style={{ textAlign: "justify" }}>
          BhanaPatel Associates is dedicated to facilitating career advancement
          on a global scale by forging connections between skilled professionals
          from Asian countries and prestigious employers across the Gulf, Europe,
          and beyond. Our goal is to offer a bespoke and exceptional service that
          surpasses all expectations and cultivates enduring partnerships founded
          on the principles of honesty, distinction, and reliability. Our mission 
          is to enable our clients to attain their business targets by identifying,
          evaluating, and selecting the most exceptional candidates available, while
          also providing our candidates with enriching and fulfilling career opportunities
          that align with their expertise and aspirations.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default MissionCard;
