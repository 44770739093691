import React from "react";
import { Col, Row } from "react-bootstrap";
import qcon from "../../Assets/Projects/qcon.jpg";
import descon from "../../Assets/Projects/descon.jpg";
import adyard from "../../Assets/Projects/adyard.jpg";
import binladin from "../../Assets/Projects/binladin.jpg";
import arabian from "../../Assets/Projects/arabian.jpg";
import drydocks from "../../Assets/Projects/drydocks.jpg";
import albalagh from "../../Assets/Projects/albalagh.jpg";
import almulla from "../../Assets/Projects/almulla.jpg";
import alshaya from "../../Assets/Projects/alshaya.jpg";
import altradcape from "../../Assets/Projects/altradcape.jpg";
import arabianmep from "../../Assets/Projects/arabianmep.jpg";
import bec from "../../Assets/Projects/bec.jpg";
import kaefer from "../../Assets/Projects/kaefer.jpg";
import galfar from "../../Assets/Projects/galfar.jpg";
import emrill from "../../Assets/Projects/emrill.jpg";
import doosan from "../../Assets/Projects/doosan.jpg";
import kier from "../../Assets/Projects/kier.jpg";
import khansaheb from "../../Assets/Projects/khansaheb.jpg";
import limak from "../../Assets/Projects/limak.jpg";
import sts from "../../Assets/Projects/sts.jpg";
import limakt from "../../Assets/Projects/limakt.jpg";
import madina from "../../Assets/Projects/madina.jpg";
import mcsc from "../../Assets/Projects/mcsc.jpg";
import nbtc from "../../Assets/Projects/nbtc.jpg";
import smasco from "../../Assets/Projects/smasco.jpg";
import satorp from "../../Assets/Projects/satorp.jpg";
import sas from "../../Assets/Projects/sas.jpg";
import transguard from "../../Assets/Projects/transguard.jpg";
import aramco from "../../Assets/Projects/aramco.png";
import tcon from "../../Assets/Projects/tcon.png";










function Coolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
     
     
     <Col xs={4} md={2} className="cool-icons">        
          <img src={tcon} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">        
          <img src={qcon} alt="about" /> 
      </Col>
      <Col xs={4} md={2} className="cool-icons">
          <img src={descon} alt="about" /> 
      </Col>
      <Col xs={4} md={2} className="cool-icons">
           <img src={adyard} alt="about" /> 
      </Col>
      <Col xs={4} md={2} className="cool-icons">
           <img src={binladin} alt="about" /> 
      </Col>
      <Col xs={4} md={2} className="cool-icons">
           <img src={arabian} alt="about" /> 
      </Col>
      <Col xs={4} md={2} className="cool-icons">
           <img src={drydocks} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={albalagh} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={almulla} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={alshaya} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={altradcape} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={arabianmep} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={bec} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={kaefer} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={galfar} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={emrill} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={doosan} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={kier} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={khansaheb} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={limak} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={sts} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={limakt} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={madina} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={mcsc} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={nbtc} alt="about" /> 
      </Col>


      <Col xs={4} md={2} className="cool-icons">
           <img src={smasco} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={satorp} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={sas} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={transguard} alt="about" /> 
      </Col>

      <Col xs={4} md={2} className="cool-icons">
           <img src={aramco} alt="about" /> 
      </Col>

    </Row>
  );
}

export default Coolstack;
