import React from "react";
import Card from "react-bootstrap/Card";

function DemoCard() {
  return (
    <Card className="quoteer-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          Amid the dynamic shifts of the industry landscape, our unwavering 
          commitment lies in comprehending fundamental requisites and delivering 
          superior talent. Our stringent selection process guarantees that only the 
          finest individuals rise to the occasion, poised to redefine the trajectories
           of diverse sectors. With experience as our cornerstone, we are your pathway 
           to cultivating tomorrow's industry leaders.
          <br/>
           Remaining attuned to global demands is imperative for business growth. 
           This is precisely why BhanaPatel Associates extends its proficiency to 
           effectively manage concerns and navigate complex scenarios. We curate a 
           diverse talent pool, catering to a spectrum of experiences that align with 
           industry requisites. Our workforce solutions are meticulously tailored to meet 
           precise demands, propelling your business towards a thriving tomorrow.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default DemoCard;
