import React from "react";
import { Container, Row,Col} from "react-bootstrap";
import Particle from "../Particle";
import Coolstack from "../Client/Coolstack";




function Client() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
      <h1 className="project-heading">
          Our <strong className="purple">Clients </strong>
        </h1>
        <Coolstack />
      </Container>
    </Container>
  );
}

export default Client;