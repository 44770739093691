import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
// import Card from "./Cards";
import CallText from "./CallText";
import service3 from "../../Assets/Projects/service3.png";
import service13 from "../../Assets/Projects/service13.jpg";
import service9 from "../../Assets/Projects/service9.jpg";
import service1 from "../../Assets/Projects/service1.png";
import service4 from "../../Assets/Projects/service4.png";
import service12 from "../../Assets/Projects/service12.jpg";


import {
  AiFillGithub,
  AiOutlineTwitter,
  AiFillInstagram,
} from "react-icons/ai";
import { FaLinkedinIn } from "react-icons/fa";
import Cards from "./Cards";

function Home2() {
  return (
    <Container fluid className="home-about-section" id="about">
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              About <span className="purple"> Us </span> 
            </h1>
            <p className="home-about-body">
            Human Resource Recruitment and Management something that Bhanapatel Associates
            has mastered to the level of a science and an art. What organizations need is 
            an employee who matches their organizational size, culture, work environment, 
            business expectations and above all with compatible behaviour and a healthy attitude. 
            Over 3 decades in this profession, our recruitment centers have access to top quality 
            expertise worldwide. Bhanapatel Associates is an ISO certified Global Human Resource 
            Consultants, based in India having branches at Surat, Vadodara with associate offices in 
            Diamonds city in India. Bhanapatel Associates is approved by Ministry of External
            Affairs, Govt.Bhanapatel Associates is synergetic emergence from each of its constituent 
            Processes, People and Practices.




              {/* <br />
              <br />I am fluent in classics like
              <i>
                <b className="purple"> C++, Javascript and Go. </b>
              </i>
              <br />
              <br />
              My field of Interest's are building new &nbsp;
              <i>
                <b className="purple">Web Technologies and Products </b> and
                also in areas related to{" "}
                <b className="purple">
                  Blockchain.
                </b>
              </i>
              <br />
              <br />
              Whenever possible, I also apply my passion for developing products
              with <b className="purple">Node.js</b> and
              <i>
                <b className="purple">
                  {" "}
                  Modern Javascript Library and Frameworks
                </b>
              </i>
              &nbsp; like
              <i>
                <b className="purple"> React.js and Next.js</b>
              </i> */}
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        

        <Container>
        <h1 className="project-heading">
          OUR <strong className="purple">SERVICES </strong>
        </h1>
        <p style={{ color: "white" }}>
          At Bhanapatel Associates, we provide
          a comprehensive range of HR services that include:
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="protect-card">
            <Cards
              imgPath={service1}
              isBlog={false}
              title="Overseas Recruitment"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>
          <Col md={4} className="protect-card">
            <Cards
              imgPath={service12}
              isBlog={false}
              title="HR Consultancy Service"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>

          <Col md={4} className="protect-card">
            <Cards
              imgPath={service9}
              isBlog={false}
              title="Staffing Services"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>

          <Col md={4} className="protect-card">
            <Cards
              imgPath={service3}
              isBlog={false}
              title="Healthcare Recruitment"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>

          <Col md={4} className="protect-card">
            <Cards
              imgPath={service4}
              isBlog={false}
              title="Outsourcing"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>

        <Col md={4} className="protect-card">
            <Cards
              imgPath={service13}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>
        </Row>
      </Container>





     <CallText/>
      {/* <Container>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col className="pro-card">
            <Cards
              imgPath={service1}
              isBlog={false}
              title="Overseas Recruitment"
              gdLink="https://bhanapatel.com/service"
            />
          </Col>
        </Row>
      </Container> */}




























        <Row>
          <Col md={12} className="home-about-social">
            <h1>FIND ME ON</h1>
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://twitter.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineTwitter />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.instagram.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillInstagram />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
