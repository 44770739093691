import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import service14 from "../../Assets/service14.jpg";
import service15 from "../../Assets/service15.jpg";
import service16 from "../../Assets/service16.jpg";
import service17 from "../../Assets/service17.jpg";
import service6 from "../../Assets/service6.jpg";
import Growthcard from "./Growthcard"; 
import Rangecard from "./Rangecard";
import MissionCard from "./MissionCard";
import VisionCard from "./VisionCard";



function About() {
  return (
    <Container fluid className="about-section">
      <Particle />
      <Container>
      <h1 className="project-heading">
          About <strong className="purple">Us </strong>
        </h1>

        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "50px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "1.6em", textAlign:"justify", paddingTop: "90px"}}>
            Customized <strong className="purple">Recruitment Solutions</strong>
            </h1>
            <Aboutcard />
          </Col>
          <Col
            md={5}
            style={{ paddingTop: "80px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={service14} alt="about" className="img-fluid" style={{borderRadius:"20px"}} />
          </Col>





          <Col
            md={5}
            style={{}}
            className="about-img"
          >
            <img src={service15} alt="about" className="img-fluid"  style={{borderRadius:"20px"}}/>
          </Col>

          <Col
            md={7}
            style={{
            justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1.6em", textAlign:"justify", paddingTop: "70px"}}>
            Supporting The Career <strong className="purple">Growth Of Candidates</strong>
            </h1>
            <Growthcard />
          </Col>
         


          <Col
            md={7}
            style={{
            justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1.6em", textAlign:"justify", paddingTop: "120px"}}>
            Comprehensive <strong className="purple">Range Of HR Services </strong>
            </h1>
            <Rangecard />
          </Col>


          <Col
            md={5}
            style={{}}
            className="about-img"
          >
            <img src={service16} alt="about" className="img-fluid" style={{borderRadius:"20px", marginTop: "40px"}} />
          </Col>







          <Col
            md={5}
            style={{}}
            className="about-img"
          >
            <img src={service17} alt="about" className="img-fluid" style={{borderRadius:"20px", marginTop: "80px"}} />
          </Col>

          <Col
            md={7}
            style={{
              justifyContent: "center",
            }}
          >
            <h1 style={{ fontSize: "1.6em", textAlign:"justify",paddingTop: "80px"}}>
            Our <strong className="purple">Mission</strong>
            </h1>
            <MissionCard />
          </Col>




          

          <Col
            md={7}
            style={{
              justifyContent: "center",
            
            }}
          >
            <h1 style={{ fontSize: "1.6em", textAlign:"justify" , paddingTop: "70px"}}>
            Our <strong className="purple">Vision</strong>
            </h1>
            <VisionCard />
          </Col>

          <Col
            md={5}
            style={{}}
            className="about-img"
          >
            <img src={service6} alt="about" className="img-fluid" style={{borderRadius:"20px", marginTop: "70px"}} />
          </Col>
        </Row>
        <h1 className="project-heading">
        Why to choose <strong className="purple">BhanaPatel Associates?</strong>
        </h1>
        <Techstack />
        {/* <h1 className="project-heading">
          <strong className="purple">Our</strong> Client
        </h1>
        <Coolstack /> */}
      </Container>
    </Container>
  );
}

export default About;