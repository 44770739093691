import React from "react";
import Card from "react-bootstrap/Card";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote">
          <p style={{ textAlign: "justify" }}>
            BhanaPatel Associates existence is to providing customized recruitment solutions to 
            organizations across various industries. We have a team of experienced recruiters who 
            are dedicated to delivering personalized and transparent services to both employers 
            and job seekers.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
