import React from "react";
import Card from "react-bootstrap/Card";
// import { ImPointRight } from "react-icons/im";

function TeamCard() {
  return (
    <Card className="quotee-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
          At BhanaPatel Associates, we have a team of experienced recruiters who have a deep understanding of the recruitment industry and a passion for connecting top talent with leading organizations. Our team is dedicated to providing personalized and transparent services to both employers and job seekers.
            <br />
          We believe in continuous learning and development, which is why we invest in the training and skill development of our recruiters. Our team is equipped with the latest tools and technologies to provide efficient and effective recruitment solutions to our clients.
            <br />
            <br />
            We also have a team of HR consultants who have extensive experience in various industries and HR functions. Our consultants work closely with our clients to understand their HR needs and provide customized solutions to meet them.          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default TeamCard;
