import React from "react";
import Card from "react-bootstrap/Card";

function VisionCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote">
          <p style={{ textAlign: "justify" }}>
           Our vision is to be the leading recruitment agency globally, known for our 
           excellence in identifying, attracting and retaining the best talent for our
           clients across the world. We strive to create a world where everyone has 
           access to equal job opportunities regardless of their nationality or ethnicity,
           and where companies can rely on us to find the perfect fit for their organization.
           At BhanaPatel Associates we are committed to making a positive difference in the 
           world by helping people and businesses achieve their full potential. We strive 
           to be the leading recruitment agency in the world, admired for our commitment 
           to quality, ethics, and social responsibility, and for the positive impact we
           make on the communities we serve.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default VisionCard;
