import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import service18 from "../../Assets/service18.jpg";
import service19 from "../../Assets/service19.jpg";
import ServiceCard from "./ServiceCards";
import Particle from "../Particle";
import DemoCard from "./DemoCard";
import service3 from "../../Assets/Projects/service3.png";
import service6 from "../../Assets/Projects/service6.jpg";
import service12 from "../../Assets/Projects/service12.jpg";
import service5 from "../../Assets/Projects/service5.png";
import service4 from "../../Assets/Projects/service4.png";
import service1 from "../../Assets/Projects/service1.png";

function Services() {
  return (
    <Container fluid className="aboutt-section">
      <Particle />
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
            <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
              Serv<strong className="purple">ices</strong>
            </h1>
            <DemoCard />
          </Col>
          
          <Col
            md={5}
            style={{ paddingTop: "120px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={service18} alt="about" className="img-fluid" style={{borderRadius:"20px"}}/>
          </Col>
        </Row>
    
      </Container>

















      <Container>
        <h1 className="project-heading">
        OUR <strong className="purple">SERVICES </strong>
        </h1>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service5}
              isBlog={false}
              title="Overseas Recruitment"
              description="Our overseas recruitment services are designed to help foreign companies to find top talent from Asian Countries. We have extensive experience in recruiting for various countries and regions, including the Gulf countries, Europe, United States and other regions. We provide end-to-end recruitment solutions, such as sourcing, screening, shortlisting, interviewing, and onboarding."
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service1}
              isBlog={false}
              title="HR Consultancy Service"
              description="Our HR Consultancy services are designed to help organizations improve their overall HR performance and effectiveness. We offer customized solutions that cater to your specific HR needs, such as HR policy development, performance management, compensation and benefits, employee engagement, and talent management."
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service12}
              isBlog={false}
              title="Staffing Services"
              description="Our staffing services provide flexible staffing solutions to meet your short-term and long-term staffing requirements. We specialize in providing high-quality staffing solutions for various industries and positions, such as Construction, Oil and Gas, Mining, Petrochemicals, Manufacturing, Agriculture, IT, Engineering, Healthcare, Automobile, Finance, Administration, Hospitality, Facility Management, and Logistic."              
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service3}
              isBlog={false}
              title="Healthcare Recruitment"
              description="Our healthcare recruitment services are designed to connect top healthcare professionals with leading healthcare organizations. We have a vast network of qualified healthcare professionals across various specialties and regions, such as doctors, nurses, pharmacists, Paramedical staffs and allied health professionals.."
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service4}
              isBlog={false}
              title="Outsourcing"
              description="Our outsourcing services are designed to help companies reduce costs, increase efficiency, and improve their overall performance. We offer customized outsourcing solutions that cater to your specific needs, such as payroll processing, HR administration, finance and accounting, and customer service."
            />
          </Col>

          <Col md={4} className="project-card">
            <ServiceCard
              imgPath={service6}
              isBlog={false}
              title="Face Recognition and Emotion Detection"
              description="Trained a CNN classifier using 'FER-2013 dataset' with Keras and tensorflow backened. The classifier sucessfully predicted the various types of emotions of human. And the highest accuracy obtained with the model was 60.1%.
              Then used Open-CV to detect the face in an image and then pass the face to the classifer to predict the emotion of a person." 
            />
          </Col>
        </Row>
      </Container>












      <Container fluid className="aboutte-section">
      <Container>
        <Row style={{ justifyContent: "center", padding: "10px" }}>
          <Col
            md={5}
            style={{ paddingTop: "50px", paddingBottom: "50px" }}
            className="about-img"
          >
            <img src={service19} alt="about" className="img-fluid" style={{borderRadius:"20px"}} />
          </Col>

          <Col
            md={7}
            style={{
              justifyContent: "center",
              paddingTop: "30px",
              paddingBottom: "50px",
            }}
          >
        
            <DemoCard />
          </Col>
        </Row>
    
      </Container>
      </Container>
























    </Container>
  );
}

export default Services;
